import React, { Component, lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router";
import { connect } from "react-redux";

import { BigLoader } from "./../components/UI/Loader/Loader";
import HomePage from "./../components/Views/HomePage/HomePage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Error404 from "./../components/Views/Pages/Error404/Error404";
import * as action from "./../store/action/index";
import styles from "./App.module.css";

class App extends Component {
  componentDidMount() {
    this.props.autoLogin();
  }
  render() {
    const VerifyEmail = lazy(() =>
      import("./../components/Views/Pages/VerifyEmail/VerifyEmail")
    );
    const VerifyPassword = lazy(() =>
      import(
        "./../components/Forms/CommonForm/ForgetPass/VerifyPass/VerifyPass"
      )
    );
    const ForgetPassword = lazy(() =>
      import("./../components/Forms/CommonForm/ForgetPass/ForgetPass")
    );
    const ResetPassword = lazy(() =>
      import("./../components/Forms/CommonForm/ResetPass/ResetPassword")
    );
    const SupportPage = lazy(() =>
      import("./../components/Views/SupportPage/SupportPage")
    );
    const AboutUs = lazy(() => import("./../components/Views/AboutUs/AboutUs"));
    const Signup = lazy(() =>
      import("./../components/Forms/Signup/Faculty/Signup")
    );
    const FacultyView = lazy(() =>
      import("./../components/Views/FacultyView/FacultyView")
    );
    const AdminView = lazy(() =>
      import("./../components/Views/AdminView/AdminView")
    );

    let routes = <BigLoader />;
    if (!this.props.token && !this.props.loading) {
      routes = (
        <>
          <div className={styles.BackGround}>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Suspense fallback={<BigLoader />}>
                <Switch>
                  <Route path="/signup" exact component={Signup} />
                  <Route
                    path="/forgetpassword/:role"
                    component={ForgetPassword}
                  />
                  {/* <Route path="/resetpassword" exact component={ResetPassword} /> */}
                  <Route
                    path="/verifyemail/:role/:token"
                    exact
                    component={VerifyEmail}
                  />
                  <Route
                    path="/verifypassword/:role/:token"
                    exact
                    component={VerifyPassword}
                  />
                  <Route path="/support" exact component={SupportPage} />
                  <Route path="/aboutus" exact component={AboutUs} />
                  {this.props.token ? "" : <Redirect to="/" exact />}
                </Switch>
              </Suspense>
            </Switch>
          </div>
        </>
      );
    }
    if (this.props.token && this.props.role === "faculty") {
      routes = (
        <>
          <Switch>
            <Redirect from="/" to="/faculty" exact />
            <Suspense fallback={<BigLoader />}>
              <Switch>
                <Route path="/faculty" component={FacultyView} />
                <Route path="/support" exact component={SupportPage} />
                <Route path="/aboutus" exact component={AboutUs} />
                <Route exact component={Error404} />
              </Switch>
            </Suspense>
            <Route exact component={Error404} />
          </Switch>
        </>
      );
    }
    if (this.props.token && this.props.role === "admin") {
      routes = (
        <>
          <Switch>
            <Redirect from="/" to="/admin" exact />
            <Suspense fallback={<BigLoader />}>
              <Switch>
                <Route path="/admin" component={AdminView} />
                <Route path="/support" exact component={SupportPage} />
                <Route path="/aboutus" exact component={AboutUs} />
                <Route exact component={Error404} />
              </Switch>
            </Suspense>
            <Route exact component={Error404} />
          </Switch>
        </>
      );
    }

    let startTime = Date.now();
    let currentTime = startTime;

    let elapsedTime = 0;
    window.addEventListener("mousemove", (event) => {
      startTime = Date.now();
      currentTime = startTime;
    });

    const tick = () => {
      elapsedTime = currentTime - startTime;
      currentTime = Date.now();

      if (elapsedTime > 900000) {
        window.location.href = window.location.origin;
        startTime = Date.now();
        localStorage.removeItem("token");
      }
      window.requestAnimationFrame(tick);
    };

    tick();

    return (
      <div className={styles.App}>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          // hideProgressBar={false}
          // newestOnTop={false}
          closeOnClick
          // rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {routes}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    loading: state.auth.loading,
    role: state.auth.role,
  };
};

const mapsDispatchToProps = (dispatch) => {
  return {
    autoLogin: () => {
      dispatch(action.checkAutoLogin());
    },
  };
};

export default connect(mapStateToProps, mapsDispatchToProps)(App);
