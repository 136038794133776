import * as actionTypes from "./../action/actionTypes";
const initialState = {
  token: null,
  error: false,
  loading: true,
  email: null,
  role: null,
  isLoggedIn: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      if (action.status === 200) {
        return {
          ...state,
          token: action.token,
          email: action.email,
          role: action.role,
          isLoggedIn: true,
          loading: false,
        };
      } else {
        return {
          ...state,
          error: true,
        };
      }
    case actionTypes.LOGOUT:
      return {
        token: null,
        error: false,
        loading: false,
        email: null,
        role: null,
        isLoggedIn: false,
      };
    case actionTypes.RESET_PASSWORD:
      if (action.status === 200) {
        return {
          ...state,
          token: action.token,
          email: action.email,
          role: action.role,
          isLoggedIn: true,
        };
      } else {
        return {
          ...state,
          error: true,
        };
      }
    default:
      return state;
  }
};

export default reducer;
