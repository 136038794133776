import React from "react";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";
import { renderField } from "./../../InputRender/InputRender";
import { Link } from "react-router-dom";
import { SmallLoader } from "./../../../UI/Loader/Loader";
import styles from "./../Login.module.css";
import overlayStyles from "./../../../Views/HomePage/HomePage.module.css";
import * as action from "./../../../../store/action/index";
import { FORM_ERROR } from "final-form";
import { EMAIL_ID } from "../../../../variable";

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (
    !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@((somaiya\.edu)$)/i.test(
      values.email
    )
  ) {
    errors.email = EMAIL_ID;
  }
  return errors;
};

const facultyLogin = (props) => {
  const { tab } = props;
  const submit = (values) => {
    return props
      .login(
        {
          email: values.email,
          password: values.password,
        },
        "/faculty",
        "FacultyLogin"
      )
      .then((res) => {
        if (res && res["FINAL_FORM/form-error"])
          return { [FORM_ERROR]: res["FINAL_FORM/form-error"] };
      });
  };
  return (
    <div
      className={`${overlayStyles.Form_Container} ${overlayStyles.Faculty_Container}`}
    >
      <Form validate={validate} onSubmit={submit}>
        {({ handleSubmit, pristine, submitting, submitError }) => (
          <form className={overlayStyles.Form} onSubmit={handleSubmit}>
            <div>
              <h1 className={styles.Login_Title}>
                <em>Faculty Login</em>
              </h1>
              <Field
                name="email"
                type="email"
                component={renderField}
                label="Email"
                placeholder="Email ID"
                id="FacultyLoginEmail"
                tabIndex={tab ? "" : "-1"}
              />
              <Field
                name="password"
                type="password"
                component={renderField}
                label="Password"
                placeholder="Password"
                id="FacultyLoginPassword"
                autoComplete="off"
                tabIndex={tab ? "" : "-1"}
              />
              <div className={styles.Login_Error}>
                {submitError && <strong>{submitError}</strong>}
              </div>
              <div className={styles.Login_Buttons}>
                {submitting ? (
                  <SmallLoader />
                ) : (
                  <>
                    <button
                      className={styles.Login_Button}
                      type="submit"
                      disabled={submitting || pristine}
                      tabIndex={tab ? "" : "-1"}
                    >
                      Login
                    </button>
                  </>
                )}
              </div>
              <div className={styles.Faculty_Links}>
                <Link
                  to="/signup"
                  className={styles.Faculty_Link}
                  tabIndex={tab ? "" : "-1"}
                >
                  SignUp??
                </Link>
                <Link
                  to="/forgetpassword/faculty"
                  className={styles.Faculty_Link}
                  tabIndex={tab ? "" : "-1"}
                >
                  Forget Password?
                </Link>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data, link, form) => dispatch(action.login(data, link, form)),
    logout: () => dispatch(action.logout),
  };
};
export default connect(null, mapDispatchToProps)(facultyLogin);
