import axios from "./../../api/axios";
import { FORM_ERROR } from "final-form";

export const facProjectsEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/project/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const facAcIdEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/id/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const facPastExperienceEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/pastexp/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const facAchievementsEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/achievement/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const facPatentsEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/patent/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const facPersonalDetailsEdit = (data, token) => {
  return () => {
    return axios
      .patch(`/faculty/personaldetail`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};
export const facPublicationsEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/publication/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};
export const facPublicationsEditRem = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/reimbursedpublication/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const facExpertiseInEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/expertise/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const facResearchEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/research/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const facAcademicQualifyEdit = (data, token) => {
  return () => {
    return axios
      .patch(`/faculty/acadqualify`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const facCertificationsEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`faculty/certification/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const facConsultancyEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`faculty/consultancy/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const facSttpOrganizedEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`faculty/sttporg/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const facSttpAttendedEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`faculty/sttpattended/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const facSttpConductedEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`faculty/sttpcond/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const facProjectsReimEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`faculty/reimbursedproject/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};
export const facPatentReimbursementEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`faculty/reimbursedpatent/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};
