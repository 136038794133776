import axios from "./../../api/axios";
import { FORM_ERROR } from "final-form";

export const addAdmin = (data, token) => {
  return () => {
    return axios
      .post("/admin/signup", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.warn(err);
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const editAdmin = (data, token) => {
  return () => {
    return axios
      .patch("/admin/name", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.warn(err);
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const deleteAdmin = (id, token) => {
  return () => {
    return axios
      .delete(`/admin/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.warn(err);
        console.warn(err?.response?.data?.message);
      });
  };
};

export const getAdminFile = (url, token) => {
  return () => {
    axios
      .post(
        "/admin/files",
        {
          filePath: url,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        window.open(window.URL.createObjectURL(res.data));
      })
      .catch((err) => {
        const fr = new FileReader();
        fr.onload = () => {
          console.warn(JSON.parse(fr.result));
        };
        fr.readAsText(err.response.data);
        window
          .open(window.URL.createObjectURL(err.response.data))
          .document.write("<h1>File Not Found</h1>");
      });
  };
};
