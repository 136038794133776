import * as actionTypes from "./actionTypes";
import axios from "./../../api/axios";
import { FORM_ERROR } from "final-form";

export const loggedIn = (email, token, role, status) => {
  return {
    type: actionTypes.LOGIN,
    email: email,
    token: token,
    role: role,
    status: status,
  };
};
export const resetted = (email, token, role, status) => {
  return {
    type: actionTypes.RESET_PASSWORD,
    email: email,
    token: token,
    role: role,
    status: status,
  };
};

export const login = (data, link) => {
  return (dispatch) => {
    return axios
      .post(`${link}/login`, data)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("role", res.data.role);
        localStorage.setItem("expire", new Date(res.data.expDate));
        localStorage.setItem("email", res.data.email);
        dispatch(
          loggedIn(res.data.email, res.data.token, res.data.role, res.status)
        );
      })
      .catch((err) => {
        console.warn(err);
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};

export const logout = () => {
  localStorage.clear();
  return {
    type: actionTypes.LOGOUT,
  };
};

export const checkAutoLogin = () => {
  return (dispatch) => {
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const expiresAt = localStorage.getItem("expire");
    const email = localStorage.getItem("email");
    if (
      !token ||
      !email ||
      !role ||
      new Date(expiresAt).getTime() <= new Date().getTime()
    ) {
      dispatch(logout());
    } else {
      axios
        .get(`${role}/autologin`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch(loggedIn(email, token, role, 200));
        })
        .catch((err) => {
          console.warn(err);
          dispatch(logout());
        });
    }
  };
};

export const resetPassword = (data, token, link) => {
  return (dispatch) => {
    return axios
      .patch(`/${link}/password`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("role", res.data.role);
        localStorage.setItem("expire", new Date(res.data.expDate));
        localStorage.setItem("email", res.data.email);
        dispatch(
          resetted(res.data.email, res.data.token, res.data.role, res.status)
        );
      })
      .catch((err) => {
        console.warn(err);
        console.warn(err?.response?.data?.message);
        return {
          [FORM_ERROR]: err.response.data.message,
        };
      });
  };
};
