import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import clgLogo from "./../../static/images/Logo.jpg";
import insta from "./../../static/images/instalogo.png";
import facebook from "./../../static/images/facebooklogo.png";
import youtube from "./../../static/images/youtubelogo.png";

import trustLogo from "./../../static/svg/TRUST_LOGO.svg";

const footer = (props) => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.links}>
        <div className={styles.somaiya}>
          <a
            href="https://kjsieit.somaiya.edu/en"
            rel="noopener noreferrer"
            target="_blank"
          >
            KJSIT
          </a>
        </div>
        <div className={styles.support}>
          <Link to="/support">Support</Link> |{" "}
          <Link to="/aboutus">About Us</Link>
        </div>
        <div className={styles.vidyavihar}>
          <a
            href="https://somaiya.edu/en"
            rel="noopener noreferrer"
            target="_blank"
          >
            Somaiya Vidyavihar
          </a>
        </div>
      </div>
      <div className={styles.handles}>
        <div>
          <img src={clgLogo} className={styles.somaiyabox} alt="Somaiya Logo" />
        </div>
        <div className={styles.mainhandles}>
          <a href="https://www.instagram.com/kjsieit_22/" target="_blank">
            {" "}
            <img src={insta} className={styles.instabox} alt="Insta Logo" />
          </a>
          <a href="https://www.facebook.com/kjsieitofficial" target="_blank">
            <img
              src={facebook}
              className={styles.facebook}
              alt="Facebook Logo"
            />
          </a>
          <a href="https://www.youtube.com/kjsieitofficial" target="_blank">
            <img
              src={youtube}
              className={styles.youtubelogo}
              alt="You Tube Logo"
            />
          </a>
        </div>

        <div className={styles.trustbox}>
          <img src={trustLogo} className={styles.trustLogo} alt="Trust Logo" />
        </div>
      </div>
      <div className={styles.prepared}>
        <p>Prepared by </p> <b>Department of Computer Engineering</b>
      </div>
      <div className={styles.credits}>
        <p>
          Guided by : Dr. Sarita Ambadekar, Associate Professor, HOD. Computer
          Engineering Department.
        </p>
        <p>
          Developed by :{" "}
          <b>
            <a>Shivam Panchal</a>
            <a>Pooja Raval </a>
            <a>Ritika Keni </a>
            <a>Shithil Shetty</a>
            <a>Krithika Naidu</a>
            <a>Sridhar Menon</a>
            <a>Adil Khatri</a>
            <a>Pruthviraj Patil</a>
          </b>
        </p>
      </div>
    </div>
  );
};
export default footer;
