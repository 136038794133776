import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./HomePage.module.css";
import HomeContainer from "./HomeContainer/HomeContainer";
import Footer from "./../../Footer/Footer";

class HomePage extends Component {
  render() {
    return (
      <>
        <div className={styles.Home}>
          <HomeContainer />
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(HomePage);
