import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./../../../Footer/Footer";

export default function error404() {
  return (
    <div style={{ height: "100%" }}>
      <div style={{ height: "100%" }}>
        <h1>Page Not Found</h1>
        <NavLink to="/">Home</NavLink>
      </div>
      <Footer />
    </div>
  );
}
