import React from "react";

import clgLogo from "./../../static/images/Logo.jpg";
import trustLogo from "./../../static/svg/TRUST_LOGO.svg";
import history from "./../../history";
import styles from "./Header.module.css";
const header = () => {
  return (
    <>
      <div className={styles.headerContainer}>
        <img
          className={styles.Logo_Img}
          src={clgLogo}
          alt="College Logo"
          onClick={() => {
            history.push("/");
          }}
        />

        <h1>
          SOMAIYA
          <h2>VIDYAVIHAR</h2>
          <h4 className={styles.Typography}>
            <h4>K. J. Somaiya Institute of Technology</h4>
            An Autonomous Institute Affiliated to University of Mumbai.
            <br />
            Accredited by NAAC and NBA, Approved by AICTE, New Delhi.
          </h4>
        </h1>

        <br />

        <ul>
          <img src={trustLogo} className={styles.Trust_Logo} alt="Trust Logo" />
        </ul>
      </div>
    </>
  );
};
export default header;
