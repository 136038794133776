import axios from "./../../api/axios";
import { FORM_ERROR } from "final-form";

export const personal = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/personaldetail`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const academicQualify = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/acadqualify`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};

export const facAddAcad = (AddEdu, data, token) => {
  return () => {
    return axios
      .post(`/faculty/onequalify/${AddEdu}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const pastExperience = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/pastexp`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const achieve = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/achievement`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const memProf = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/memprof`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const memCom = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/memcomm`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const acId = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/id`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const expert = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/expertise`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const facResearch = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/research`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const facPatents = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/patent`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const facProjects = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/project`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const facPublications = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/publication`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const facPublicationsRem = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/reimbursedpublication`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};

export const facCertifications = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/certification`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};

export const facConsultancy = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/consultancy`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};

export const facSttpOrganized = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/sttporg`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};

export const facSttpAttended = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/sttpattended`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const facSttpConducted = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/sttpcond`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};

export const facProjectsReim = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/reimbursedproject`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
export const facPatentReimbursement = (data, token) => {
  return () => {
    return axios
      .post(`/faculty/reimbursedpatent`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.warn(err?.response?.data?.message);
        return { [FORM_ERROR]: err.response.data.message };
      });
  };
};
