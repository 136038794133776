import AdminLogin from "./../../../Forms/Login/AdminLogin/AdminLogin";
import FacultyLogin from "./../../../Forms/Login/FacultyLogin/FacultyLogin";
import React, { Component } from "react";
import Overlay from "./Overlay/Overlay";
import styles from "./../HomePage.module.css";
class HomeContainer extends Component {
  state = {
    rightPanelActive: true,
  };

  handleClickFacultyButton = () =>
    this.setState({
      rightPanelActive: false,
    });

  handleClickAdminButton = () =>
    this.setState({
      rightPanelActive: true,
    });

  render() {
    const { handleClickFacultyButton, handleClickAdminButton } = this;
    const { rightPanelActive } = this.state;
    return (
      <div>
        <div className={styles.Title_Container}>
          {/* <h1 className={styles.Institute_Main}>
            K. J. Somaiya Institute of Engineering and Information Technology
          </h1>
          <h2 className={styles.Header_Sub}>
            An Autonomous Institute affiliated to University of Mumbai
          </h2>
          <h2 className={styles.Header_Sub}>
            Accredited by NAAC and NBA, Approved by AICTE, New Delhi
          </h2> */}
          <h1 className={styles.Header_Main}>
            Faculty Profile Management System
          </h1>
          <h2 className={styles.Header_Sub}>Developed by</h2>
          <h2 className={styles.Header_Sub}>
            Department of Computer Engineering
          </h2>
        </div>
        <div
          className={`${styles.Container} ${
            rightPanelActive ? `${styles.Right_Panel_Active}` : ``
          }`}
        >
          <FacultyLogin tab={this.state.rightPanelActive} />
          <AdminLogin tab={this.state.rightPanelActive} />
          <Overlay
            handleClickFacultyButton={handleClickFacultyButton}
            handleClickAdminButton={handleClickAdminButton}
            tab={this.state.rightPanelActive}
          />
        </div>
      </div>
    );
  }
}

export default HomeContainer;
