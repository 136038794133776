// Backend API
export const baseUrl = "https://fpmsbackend.kjsieit.com";
// export const baseUrl = "https://fpms-v2.herokuapp.com";

// export const baseUrl = "http://localhost:5000";

// Used for Pagination
export const PER_PAGE = 10;

// Validation Error Variables
export const MIN2_CHAR = "Minimum 2 Characters";
export const MIN10_CHAR = "Minimum 10 Characters";
export const CONTACT_NO = "Invalid Contact Number";
export const RESET_PWD = "1 upper/lower case number and special character";
export const CONFIRM_PWD = "Password Mismatch";
export const IMAGE_VAL = "Please Upload .jpeg or .jpg";
export const FILE_FORMAT = "Please Upload PDF format only.";
export const FILE_SIZE = (size) =>
  `Please Upload file which is less than 10 MB. Current file size: ${(
    size /
    (1024 * 1024)
  ).toFixed(2)}MB`;
export const URL_VAL = "Invalid URL";
export const DATE_VAL = "Invalid Date";
export const AMOUNT = "Enter Correct Amount";
export const EMAIL_ID = "Enter Valid Email-Id";
export const ACADEMIC_YEAR = "Invalid Academic Year";
export const FINANCIAL_YEAR = "Invalid Financial Year";
export const OPTION = "Select/Enter Valid Option";
export const PWD =
  "Please enter a password with minimum length of 8 and combination of uppercase, lowercase, number and special character";

// Admin Table
export const SR_NO = "Sr. No.";
export const FAC = "Faculty";

// File upload labels
export const File_Upload = {
  certificate: "Certificate",
  file: "File",
  report: "Report",
};
// Label Variables
export const Add_Academic = {
  qualification: "Qualification",
  institute: "Institute",
  addCertificate: "Add Certificate",
  totalPercent: "Total Percentage/CGPA",
  passingYear: "Passing Year",
  admYear: "Admission Year",
  spl: "Specialization",
  subSpl: "Sub Specialization",
  university: "University",
};
export const Academic = {
  institute: "Institute",
  board: "Board",
  higherType: "HSC/ Diploma",
  university: "University",
  admYear: "Admission Year",
  passingYear: "Passing Year",
  percent: "Percentage/CGPA",
  percentCGPA: "Percentage/CGPA",
  totalPercent: "Total Percentage/CGPA",
  totalCGPA: "Total Percentage/CGPA",
  sscCertificate: "SSC Certificate",
  hscCertificate: "HSC Certificate",
  ugCertificate: "UG Certificate",
  spl: "Specialization",
  subSpl: "Sub Specialization",
};
export const Experience = {
  expType: "Experience Type",
  appointmentFile: "Appointment Letter",
  releavingFile: "Releaving Letter",
  lastOrg: "Organization",
  orgURL: "Organization URL",
  orgAdd: "Organization Address",
  orgDept: "Department",
  orgDesignation: "Designation",
  workProfile: "Work Profile",
  workDetails: "Subjects Taught",
  orgResponsibility: "Responsibility",
  startDate: "Start Date",
  endDate: "End Date",
  experienceFile: "Upload Experience Certificate",
};
export const ExpertiseIn = {
  title: "Title",
  description: "Description",
};
export const MemProfessional = {
  name: "Name",
  membershipNo: "Membership Number",
  chapter: "Chapter",
  startDate: "Start Date",
  expiryDate: "Membership Expiry",
  certificate: "Certificate / Card",
};
export const Patent = {
  title: "Title",
  inventor: "Inventor",
  acadYear: "Academic Year",
  memberteam: "Team Member",
  subCategory: "Patent Category",
  assignee: "Assignee",
  appNo: "Application Number",
  patentNo: "Patent Grant Number",
  filingCountry: "Filing Country",
  filingDate: "Filing Date",
  pubDate: "Publication Date",
  grantDate: "Grant Date",
  commercialized: "Commercialized",
  agencyName: "Agency Name",
  agencyPerson: "Agency Person",
  agencyContact: "Agency Contact",
  agencyEmail: "Agency Email",
  agencyLink: "Agency Link",
  agencyType: "Agency Agreement Type",
  amount: "Agency Amount",
  file: "Agreement File",
  add: "Agency Address",
  status: "Status",
  remarks: "Remarks",
  patentCertificate: "Patent published / Grant certificate",
};
export const PersonalDetailv = {
  facultyInitial: "Faculty Initial",
  department: "Department",
  firstName: "First Name",
  middleName: "Middle Name",
  lastName: "Last Name",
  name: "Name",
  gender: "Gender",
  dateOfBirth: "Date of Birth",
  spouseFather: "Father's Full Name",
  contactNo: "Contact Number",
  extraContactNo: "Alternate Contact Number",
  aadharCardNo: "Aadhar Card Number",
  officeNo: "Office Number",
  correspondingAddress: "Corresponding Address",
  permanentAddress: "Permanent Address",
  address: "Address",
  pincode: "Pincode",
  bankDetailsp: "Bank Details:",
  bankName: "Bank Name",
  bankAccNo: "Bank Account Number",
  ifscCode: "IFSC Code",
  panCard: "Pan Card",
  email: "Email",
};
export const Achievementv = {
  achievementType: "Achievement Type",
  title: "Title of Award & Recognition",
  type: "Type",
  organizingAuthority: "Organizing Authority",
  date: "Date",
  specialization: "Specialization",
  videoLink: "Photo/Video Link",
  description: "Description",
};
export const CommitteeMem = {
  committeeName: "Committee Name",
  designation: "Designation",
  year: "Year",
  responsibilities: "Responsibilities",
};
export const AcademicIdv = {
  domain: "Domain",
  domainName: "Domain Name",
  iIndex: "I-Index",
  hIndex: "H-Index",
  dId: "ID / Registered Email Address",
};
export const Projectv = {
  funded: "Type",
  worthRs: "Worth Rupees",
  recAmount: "Recieved Amount till date",
  title: "Title",
  deporg: "Deployed at Organization",
  memberteam: "Team Member",
  orgname: "Organization Name",
  depurl: "URL of deployed Poject",
  acadYear: "Academic Year",

  rejectionReason: "Rejection Reason",
  suggestions: "Suggestions",
  acadYear1: "Academic Year 1",
  acadYear2: "Academic Year 2",
  acadYear3: "Academic Year 3",
  acadYear4: "Academic Year 4",
  acadYear5: "Academic Year 5",

  amount1: "Recieved Amount 1",
  amount2: "Recieved Amount 2",
  amount3: "Recieved Amount 3",
  amount4: "Recieved Amount 4",
  amount5: "Recieved Amount 5",

  category: "Category",
  startDate: "Start Date",
  date1: "Amount Recieved Date 1",
  date2: "Amount Recieved Date 2",
  date3: "Amount Recieved Date 3",
  date4: "Amount Recieved Date 4",
  date5: "Amount Recieved Date 5",

  endDate: "End Date",
  role: "Team Member(s)",
  fundingAgency: "Funding Agency",
  grantNo: "Grant No.",
  amount: "Sanctioned Amount",
  normalamount: "Amount",
  prinInvest: "Principal Investigator",
  status: "Status",
  coInvest: "Co-Investigator(s)",
  utilization: "Utilization",
  remarks: "Remarks",
  utilizationFile: "Utilization File",
  transaction: "Transaction No 1",
  projectReport: "Project Proposal/Funding/Reports/Completion Documents",
  transaction1: "Transaction No. 1",
  transaction2: "Transaction No. 2",
  transaction3: "Transaction No. 3",
  transaction4: "Transaction No. 4",
  transaction5: "Transaction No. 5",
};
export const ProjectReimbursementv = {
  amount: "Amount",
  recAmount: "Recieved Amount",
  reimburseAmount: "Reimbursement Amount",
  student: "Student Involved",
  amountContriFac: "Amount Paid By Faculty",
  amountContriFacStu: "Amount Paid By Faculty/Student",
  fundingAgency: "Funding Agency",
  agencyContact: "Funding Agency Contact",
  agencyEmail: "Funding Agency Email",
  acadYear: "Academic Year",
  finYear: "Financial Year",
  utilizationFile: "Utilization File",
  receipt: "Receipt",
  proofReimburse: "Reimbursement Proof",
};
export const Researchv = {
  title: "Title",
  acadYear: "Academic Year",
  startDate: "Start Date",
  endDate: "End Date",
  startTime: "Start Time",
  endTime: "End Time",
  funded: "Funded",
  guestSpeaker: "Guest Speaker",
  targetAudience: "Target Audience",
  activities: "Activities",
  description: "Description",
  remarks: "Remarks",
  videoLink: "Video Link",
  images: "Images",
  startDateTime: "Start Date and Time",
  endDateTime: "End Date and Time",
};
export const Certificationv = {
  title: "Title",
  acadYear: "Academic Year",
  startDate: "Start Date",
  endDate: "End Date",
  mode: "Mode",
  coursePlatform: "Course Platform",
  rank: "Rank",
  charges: "Charges",
  remark: "Remarks",
  amount: "Amount",
  receipt: "Receipt",
  proofReimburse: "Reimbursement Proof",
};
export const SttpOrganizedv = {
  title: "Title",
  acadYear: "Academic Year",
  fromDate: "Start Date",
  toDate: "End Date",
  level: "Level",
  orgAssoc: "Associated Organisation",
  charges: "Free/Paid",
  resPerDetail: "Resource Person Details",
  fundByClg: "Funding By College/Other Funding Agency",
  remark: "Remarks",
  fees: "Fees",
  paymentMode: "Payment Mode",
  coordinator: "Co-ordinators",
  supportStaff: "Supporting Staff",
  paymentBills: "Other Bills",
  sacLetter: "Sanctioned Letter",
};
export const SttpConductedv = {
  title: "Title",
  acadYear: "Academic Year",
  startDate: "Start Date",
  endDate: "End Date",
  organizedBy: "Organized By",
  approvedBy: "Approved By",
  mode: "Mode",
  trainingTopic: "Session Topic",
  charges: "Free/Paid",
  remark: "Remarks",
  inviteLetter: "Invitation Letter",
  appriLetter: "Appreciation Letter",
  amountReceive: "Amount Received",
  videoLink: "Video Link",
};
export const PatentReimburse = {
  filingAmount: "Patent Filing Amount",
  facAmount: "Amount Paid by Faculty",
  contriAmount: "Amount Paid by Student/Faculty",
  studentInvolved: "Student/Faculty Involved",
  acadYear: "Academic Year",
  finYear: "Financial Year",
  agencyFiling: "Patent Filed Agency",
  agencyName: "Agency Name",
  agencyPerson: "Agency Person",
  agencyContact: "Agency Contact Number",
  agencyEmail: "Agency Email",
  agencyLink: "Agency Link",
  add: "Agency Address",
  agencyType: "Agency Agreement Type",
  amount: "Agency Amount",
  file: "Agreement File",
  reimAmount: "Reimbursement Amount",
  receiptFile: "Fee Receipt",
  proofFile: "Reimbursement Proof",
};
export const Pub = {
  workType: "Type",
  secTitle: "Conference Title",
  title: "Title",
  bookTitle: "Book Title",
  journalTitle: "Journal Title",
  hostedvenue: "Hosted Venue",
  organizedBy: "Organized By",
  author: "First Author",
  Secondauth: "Second Author",
  Thirdauth: "Third Author",
  coAuthor: "Co-Author(s)",
  acadYear: "Academic Year",
  dop: "Date of Publish",
  dateIssue: "Date of Issue/Conference",
  editor: "Editor",
  publisher: "Publisher",
  id: "ISBN / E-ISSN Number",
  lvl: "Level",
  vol: "Volume",
  pgNo: "Page No.(From-To)",
  sdate: "Start Date",
  edate: "End Date",
  url: "Published Paper URL",
  abstract: "Abstract",
  doi: "DOI",
  impact: "Impact Factor",
  remarks: "Remarks",
  ugcCare: "UGC Care",
  sci: "SCI",
  scie: "SCIE",
  wos: "Web of Science",
  ebsco: "EBSCO",
  issueNo: "Issue No",
  scopus: "Scopus Index",
  other: "Other Indexes",
  sjrImpact: "SJR Impact Factor",
  scimagoImpact: "SCIMago Impact Factor",
  jcrImpact: "JCR Impact Factor",
  citation: "Citation Count",
  receipt: "Receipt File",
  file: "Accepted/Published Paper",
};
export const PublicationReimburse = {
  pubAmount: "Publication Amount",
  reimAmount: "Reimbursement Amount",
  acadYear: "Academic Year",
  finYear: "Financial Year",
  facAmount: "Total Amount Paid by Students/Other Faculties",
  contriAmount: "Details of Amount Paid by Students/Other Faculties",
  studentInvolved: "Student/Faculty Involved",
  receiptFile: "Receipt File of Publication",
  fileProof: "Proof File from KJSIEIT",
};
export const Consult = {
  title: "Project Title",
  pInvest: "Principal Investigator",
  coInvest: "Co-Investigator/s",
  status: "Status",
  acadYear: "Academic Year",
  acadYear1: "Academic Year 1",
  acadYear2: "Academic Year 2",
  acadYear3: "Academic Year 3",
  acadYear4: "Academic Year 4",
  acadYear5: "Academic Year 5",

  amount1: "Recieved Amount 1",
  amount2: "Recieved Amount 2",
  amount3: "Recieved Amount 3",
  amount4: "Recieved Amount 4",
  amount5: "Recieved Amount 5",

  date1: "Amount Recieved Date 1",
  date2: "Amount Recieved Date 2",
  date3: "Amount Recieved Date 3",
  date4: "Amount Recieved Date 4",
  date5: "Amount Recieved Date 5",

  transaction1: "Transaction No. 1",
  transaction2: "Transaction No. 2",
  transaction3: "Transaction No. 3",
  transaction4: "Transaction No. 4",
  transaction5: "Transaction No. 5",

  finYear: "Financial Year",
  colabAgency: "Collaborative Agencies",
  fundAgency: "Funding Agencies",
  fundAgencyType: "Funding Agency Type",
  quotAmount: "Quotation Amount",
  sancAmount: "Sanctioned Amount",
  recAmount: "Received Amount till date",
  remarks: "Remarks",
  projectProposal: "Project Proposal File",
  quotFile: "Quotation File",
  purchaseOrder: "Purchase Order File",
  invoice: "Invoice File",
  payProof: "Payment Proof File",
  receiptClg: "Receipt File",
  projectReport: "Project Report File",
  projectCompReport: "Project Completion Certificate",
  sanctionedDoc: "Project Sanctioned Document",
};
export const Sttp_Attended = {
  title: "Title",
  acadYear: "Academic Year",
  startDate: "Start Date",
  endDate: "End Date",
  approvedBy: "Approved By",
  orgBy: "Organized By",
  charges: "Free/Paid",
  remarks: "Remarks",
  amount: "Amount",
  feeReceipt: "Fee Receipt File",
  reimburseProof: "Reimbursement Proof File ",
};
